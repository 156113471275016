import { getAccessorType } from "typed-vuex";
import * as nenkin from "./nenkin";

export const accessorType = getAccessorType({
    modules: {
        nenkin,
    },
    state: {},
    getters: {},
    mutations: {},
    actions: {},
});
