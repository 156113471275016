import { getterTree, mutationTree, actionTree } from "typed-vuex";
import { $axios } from "~/lib/utils/api";
import { isAxiosError } from "~/lib/utils/axiosErrorCheck";
export const mutationType = {
    SET_AGE: "SET_AGE",
    SET_NENKIN_TYPE: "SET_NENKIN_TYPE",
    SET_NENKIN_INPUT: "SET_NENKIN_INPUT",
    SET_MIKOMI_NENSHU: "SET_MIKOMI_NENSHU",
    SET_NENKIN_RESULT: "SET_NENKIN_RESULT",
} as const;

type State = {
    requestInput: {
        age: number;
        nenkinType: 1 | 2;
        mikomiNenshu: number | "";
    };
    nenkinInput: NenkinInput;
    nenkinResult: NenkinResult;
};

type NenkinInput = {
    kokuminNenkinGaku: number | "";
    kikanKosei: number | "";
    kikanTotal: number | "";
    nenkinTotal: number | "";
};

export type NenkinResult = {
    success: boolean;
    simulated_at: string;
    result: {
        rorei: {
            age: number;
            detail: [{ type: number; value: string }, { type: number; value: string }];
            sum: string;
        }[];
    };
    info: {
        conditions: {
            honnin: {
                age: number;
                nenkin_type: number;
                mikomi_nenshu: number | null;
                kokumin_nenkin_gaku: number;
                kikan_kosei: number;
                kikan_total: number;
                nenkin_total: number;
            };
        };
    };
};

export const state = (): State => ({
    requestInput: { age: 0, nenkinType: 1, mikomiNenshu: "" },
    nenkinInput: { kokuminNenkinGaku: "", kikanKosei: "", kikanTotal: "", nenkinTotal: "" },
    nenkinResult: {
        success: false,
        simulated_at: "",
        result: {
            rorei: [
                {
                    age: 0,
                    detail: [
                        { type: 1, value: "" },
                        { type: 2, value: "" },
                    ],
                    sum: "",
                },
            ],
        },
        info: {
            conditions: {
                honnin: {
                    age: 0,
                    nenkin_type: 0,
                    mikomi_nenshu: 0,
                    kokumin_nenkin_gaku: 0,
                    kikan_kosei: 0,
                    kikan_total: 0,
                    nenkin_total: 0,
                },
            },
        },
    },
});

export const getters = getterTree(state, {
    getRequestInput: (state): State["requestInput"] => {
        return state.requestInput;
    },
    getNenkinInput: (state): NenkinInput => {
        return {
            kokuminNenkinGaku: state.nenkinInput.kokuminNenkinGaku,
            kikanKosei: state.nenkinInput.kikanKosei,
            kikanTotal: state.nenkinInput.kikanTotal,
            nenkinTotal: state.nenkinInput.nenkinTotal,
        };
    },
    getNenkinResult: (state): State["nenkinResult"] => {
        return state.nenkinResult;
    },
});

export const mutations = mutationTree(state, {
    [mutationType.SET_AGE]: (state, age: number) => {
        state.requestInput.age = age;
    },
    [mutationType.SET_MIKOMI_NENSHU]: (state, nenkinMikomiNenshu: number) => {
        state.requestInput.mikomiNenshu = nenkinMikomiNenshu;
    },
    [mutationType.SET_NENKIN_TYPE]: (state, nenkinTypeInput: 1 | 2) => {
        state.requestInput.nenkinType = nenkinTypeInput;
    },
    [mutationType.SET_NENKIN_INPUT]: (state, nenkinAmountInput: NenkinInput) => {
        state.nenkinInput.kokuminNenkinGaku = Number(nenkinAmountInput.kokuminNenkinGaku);
        state.nenkinInput.kikanKosei = Number(nenkinAmountInput.kikanKosei);
        state.nenkinInput.kikanTotal = Number(nenkinAmountInput.kikanTotal);
        state.nenkinInput.nenkinTotal = Number(nenkinAmountInput.nenkinTotal);
    },
    [mutationType.SET_NENKIN_RESULT]: (state, nenkinResult: NenkinResult) => {
        state.nenkinResult = nenkinResult;
    },
});

export const actions = actionTree(
    { state, getters, mutations },
    {
        /**
         * 年齢をstateに保管する
         * @param {number} age - 引数の数字
         */
        setAge({ commit }, age: number): void {
            commit(mutationType.SET_AGE, age);
        },
        /**
         * 年金種別をstateに保管する
         * @param {1|2} nenkinType - 引数の数字
         */
        setType({ commit }, nenkinType: 1 | 2): void {
            commit(mutationType.SET_NENKIN_TYPE, nenkinType);
        },
        /**
         * 見込み年収額をstateに保管する
         * @param {number} mikomiNenshu - 引数の数字
         */
        setMikomiNenshu({ commit }, mikomiNenshu: number): void {
            commit(mutationType.SET_MIKOMI_NENSHU, mikomiNenshu);
        },
        /**
         * ねんきん試算情報を取得し、stateに保管する
         * @param {NenkinInput} nenkinInput - 引数の数字
         */
        async fetchNenkinResult({ commit, state }, nenkinInput: NenkinInput): Promise<void> {
            commit(mutationType.SET_NENKIN_INPUT, nenkinInput);
            // 見込み年収を条件によって0またはNullにする
            const mikomiNenshu: { value: number | null } = { value: 0 };
            if (state.requestInput.nenkinType === 1) {
                mikomiNenshu.value = null;
            } else if (state.requestInput.age > 50) {
                mikomiNenshu.value = 0;
            } else {
                mikomiNenshu.value = state.requestInput.mikomiNenshu || 0;
            }
            try {
                const data = await $axios.post(`simulation/multi`, {
                    honnin: {
                        age: state.requestInput.age,
                        nenkin_type: state.requestInput.nenkinType,
                        mikomi_nenshu: mikomiNenshu.value,
                        kokumin_nenkin_gaku: nenkinInput.kokuminNenkinGaku,
                        kikan_kosei: nenkinInput.kikanKosei,
                        kikan_total: nenkinInput.kikanTotal,
                        nenkin_total: nenkinInput.nenkinTotal,
                    },
                });
                commit(mutationType.SET_NENKIN_RESULT, data.data);
            } catch (error) {
                if (isAxiosError(error)) {
                    alert(error.message);
                    if (error.response?.data) {
                        const messages = error.response.data as { data: []; errors: Record<string, string>; message: string };
                        alert(Object.values(messages.errors as object).join("\n"));
                        throw error.response.data;
                    }
                }
                throw error;
            }
        },
    }
);
