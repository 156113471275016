/* eslint-disable @typescript-eslint/explicit-function-return-type */
import type { NuxtAxiosInstance } from "@nuxtjs/axios";

// eslint-disable-next-line import/no-mutable-exports
let $axios: NuxtAxiosInstance;

export function initializeAxios(axiosInstance: NuxtAxiosInstance) {
    $axios = axiosInstance;
}

export { $axios };
