import { Plugin } from '@nuxt/types'
import createPersistedState from 'vuex-persistedstate'

const persistedState: Plugin = ({ store }) => {
  createPersistedState({
    key: '919nenkinSimulation',
    storage: window.sessionStorage,
  })(store)
}

export default persistedState
